<template>
  <div class="home">
    <div class="con1">
      <!-- 头部 -->
      <div class="head container">
        <div class="logo">
          <img src="../assets/image/logo.png" alt="">
          <h2>飞喵加速器</h2>
        </div>
      </div>
      <div class="first container">
        <!-- 标题 -->
        <div class="devices_title">
          <div class="row">
            <div class="col-md-5 col-sm-7 col-pc">
              <div class="device-label">台式电脑 & 笔记本电脑</div>
            </div>
            <div class="col-md-7 col-sm-5 col-mobile">
              <div class="device-label">手机 & 平板电脑 </div>
            </div>
          </div>
        </div>
        <!-- 下载设备切换 -->
        <div class="tab">
          <div class="devices">
            <div class="item" v-for="(tab, index) in tabs" :key="index" @click="changeTab(tab)"
              :class="{ active: activeTab === tab.name }">
              <div class="current download-btn win-img"
                :style="{ color: activeTab === tab.name ? tab.activeColor : tab.inactiveColor }">
                {{ tab.name }} <i :class="tab.icon"></i>
              </div>
            </div>
          </div>

          <!--begin：banner-->
          <div class="carousel">
            <!-- <div class="carousel_caption">
              <h1>下载适用于 手机 的 飞喵</h1>
              <div class="download-sub-title">
                使用 飞喵加速器 为 移动端提供终极隐私保护
              </div>
              <div class="text-center">
                <div class="tpye">
                  <div class="download-link-a btn" @click="load(3)" style="margin-right: 20px;">
                    <span>android 立即下载</span>
                  </div>
                  <div class="download-link-a btn" @click="load(4)">
                    <span>ios 立即下载</span>
                  </div>
                </div>
                <div class="free-trail">免费试用</div>
              </div>
            </div> -->
            <div class="carousel_caption" v-if="activeTab === 'Windows'">
              <h1>下载适用于 Windows 的飞喵</h1>
              <div class="download-sub-title">使用 飞喵 为 Windows 电脑提供终极隐私保护</div>
              <div class="text-center">
                <div class="tpye">
                  <div class="download-link-a btn" @click="load(5)">
                    <span>立即下载</span>
                    <!-- <span class="tip"> (X64版本)</span> -->
                  </div>
                  <!-- <div class="download-link-a btn" @click="load(6)">
                    <span>立即下载</span>
                    <span class="tip"> (Arm64版本)</span>
                  </div> -->
                </div>
                <div class="free-trail">免费试用</div>
              </div>
            </div>
            <!-- <div class="carousel_caption" v-if="activeTab === 'macOS'">
              <h1>下载适用于 Mac 的 飞喵</h1>
              <div class="download-sub-title">
                使用 飞喵加速器 为 Mac 电脑提供终极隐私保护
              </div>
              <div class="text-center">
                <div class="tpye">
                  <div class="download-link-a btn" @click="load(3)" style="margin-right: 20px;">
                    <span>立即下载</span>
                    <span class="tip"> (intel版本)</span>
                  </div>
                  <div class="download-link-a btn" @click="load(4)">
                    <span>立即下载</span>
                    <span class="tip"> (m1/m2版本)</span>
                  </div>
                </div>
                <div class="free-trail">免费试用</div>
              </div>
            </div> -->
            <div class="carousel_caption" v-if="activeTab === 'android'">
              <h1>下载适用于 android 的飞喵</h1>
              <div class="download-sub-title">使用 飞喵加速器 为 android 设备提供终极隐私保护</div>
              <div class="text-center">
                <div class="download-link-a btn" @click="load(1)">
                  立即下载
                </div>
                <div class="free-trail">免费试用</div>
              </div>
            </div>
            <div class="carousel_caption" v-if="activeTab === 'iOS'">
              <h1>下载适用于 iOS 的飞喵</h1>
              <div class="download-sub-title">使用 飞喵加速器 为 iOS 设备提供终极隐私保护</div>
              <div class="text-center">
                <div class="download-link-a btn" @click="load(2)">立即下载</div>
                <div class="free-trail">免费试用</div>
              </div>
            </div>
          </div>
          <!--end：banner-->
        </div>
      </div>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <div style="width: 100%;">
        <div class="container container-div">
          <div class="col-md-12 col-sm-6 home-body-title">
            满足您在 设备 上使用 加速器 的任意需求
          </div>

          <div class="row">
            <div class="feature-item clearfix">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 feature-list-right">
                <img src="../assets/image/convenient.png" />
                <div class="feature-list-content">
                  <h2>便捷</h2>
                  <span>操作简单，一键加速网络</span>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 feature-list-right">
                <img src="../assets/image/firm.png">
                <div class="feature-list-content">
                  <h2>稳定</h2>
                  <span>专属服务器，告别掉线</span>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 feature-list-left">
                <img src="../assets/image/speciality.png">
                <div class="feature-list-content">
                  <h2>专业</h2>
                  <span>自研技术，无惧售后</span>
                </div>
              </div>
            </div>

            <div class="feature-item clearfix">

              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 feature-list-right">
                <img src="../assets/image/qukily.png">
                <div class="feature-list-content">
                  <h2>快速</h2>
                  <span>视频、网站、游戏加速</span>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 feature-list-left">
                <img src="../assets/image/secure.png">
                <div class="feature-list-content">
                  <h2>安全</h2>
                  <span>加密传输，保护信息安全</span>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 feature-list-right">
                <img src="../assets/image/service.png">
                <div class="feature-list-content">
                  <h2>服务</h2>
                  <span>专业客服，解决售后问题</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="footer ">
      <div class="agreements">
        <div class="agreement">
          <a href="https://test.bytk.top/agreement/cancel_agreement.html" target="_blank">注销协议</a>
        </div>
        <div class="agreement">
          <a href="https://test.bytk.top/agreement/service_agreement.html" target="_blank">服务条款</a>
        </div>
        <div class="agreement">
          <a href="https://test.bytk.top/agreement/privacy_agreement.html" target="_blank">隐私政策</a>
        </div>
      </div>
      <div class="message" style="color: #fff;margin-top: 10px;">
        应用名称:飞喵加速器 &nbsp;&nbsp;| &nbsp;&nbsp;版本号:v1.0.0 &nbsp;&nbsp;| &nbsp;&nbsp;开发者:杭州魂喵科技有限公司
      </div>
      <div class="copyright">Copyright © 2024 飞喵加速器</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import { addlists,accepts } from "../api/load.js";
export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data() {
    return {
      tabs: [
        // { name: 'macOS', icon: 'iconfont icon-app-mac', activeColor: '#FF930E', inactiveColor: '#666666', content: 'Content for Tab 2' },
        { name: 'android', icon: 'iconfont icon-app-android', activeColor: '#FF930E', inactiveColor: '#666666', content: 'Content for Tab 3' },
        { name: 'iOS', icon: 'iconfont icon-app-ios', activeColor: '#FF930E', inactiveColor: '#666666', content: 'Content for Tab 4' },
        { name: 'Windows', icon: 'iconfont icon-app-win', activeColor: '#FF930E', inactiveColor: '#666666', content: 'Content for Tab 1' },
      ],
      activeTab: 'android',
      id: 2,
      channelNumber1: '',
      url:'',
    };
  },
  created() {
  },
  mounted() {
    this.adjustButtonForDevice();
    // window.location.href = window.location.href + "?channel_number=UC821255297482940"
    // console.log(window.location.href)  //http://localhost:8889/#/?channel_number=UC821255297482940
    // console.log(window.location.search)   //空
    // console.log(window.location.hash) //#/?channel_number=UC821255297482940


    const url = window.location.href;
    const match = url.match(/[?&]channel_number=([^&]*)/);
    // console.log(match)
    const channelNumber = match ? match[1] : "GW821249439972794";
    this.channelNumber1 = channelNumber

    // console.log(url)
    this.url = url
    this.accept()
  },
  methods: {
    // 系统自动识别下载设备
    getOS() {
      const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;

      // 检测iOS
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "ios";
      }
      // 检测Android
      else if (/android/i.test(userAgent)) {
        return "android";
      }
      // 检测Windows
      else if (/windows/i.test(userAgent)) {
        return "windows";
      }
      return "other";
    },
    adjustButtonForDevice() {
      const os = this.getOS();

      // 根据不同操作系统设置class和文字
      if (os === 'ios') {
        this.activeTab = 'iOS';
      } else if (os === 'android') {
        this.activeTab = 'android';
      } else if (os === 'windows') {
        this.activeTab = 'Windows';
      } else {
        this.deviceClass = '';
        this.buttonText = '下载应用';
      }
    },
    // 点击自主选择设备下载
    changeTab(tab) {
      this.activeTab = tab.name;
    },
    // 点击下载按钮
    async load(id) {
      let params = {
        device: id,
        channel_number: this.channelNumber1
      }
      const result = await addlists(params);
      // console.log("result", result.data.data.dow_address);
      window.location.href = result.data.data.dow_address
    },
    // 传给后端url
    async accept() {
      let params = {
        link: this.url
      }
      const result = await accepts(params);
      console.log(result)
    },
  }
}
</script>

<style scoped lang="less">
a {
  text-decoration: none;
}

.container {
  text-align: left;
  max-width: 1200px;
}

// .active{
//   transform: scale(1.2); /* 当鼠标悬停时，放大1.2倍 */
// }

// 手机端
@media (max-width: 767px) {
  .carousel {
    padding: 50px 15px 0px !important;
  }

  .home-body-title {
    margin: 30px auto !important;
  }

  .devices_title {
    margin-top: 0px !important;
  }

  .item {
    margin-right: 3.125rem !important;
  }

  .item:last-child {
    margin-right: 0 !important;
  }

  .download-sub-title {
    margin-bottom: 30px !important;
  }

  .container-div {
    padding: 0 0 30px !important;
  }

  .footer {
    padding: 20px 0px !important;
  }
}

.home {
  width: 100%;

  .con1 {
    background: linear-gradient(180deg, rgba(56, 143, 255, 0.1) 0%, rgba(24, 125, 255, 0.05) 61%, rgba(56, 143, 255, 0) 100%);
    overflow: hidden;

    .head {
      height: 100px;

      .logo {
        text-align: left;
        display: flex;
        justify-content: start;
        align-items: center;

        img {
          width: 50px;
          margin-right: 14px;
        }

        h2 {
          line-height: 100px;
          color: #FF930E;
        }
      }
    }

    .first {
      background: url(../assets/image/banner_mapbg@2x.png) no-repeat;
      background-size: 100%;
      // overflow: hidden;


      .devices_title {
        color: #999999;
        margin-top: 20px;
      }

      .devices {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 20px 0 0 0;

        .item {
          margin-right: 6.25rem;
        }

        .item:last-child {
          margin-right: 0;
        }

        .download-btn {
          outline: none;
          // width: 9.3125rem;
          // height: 3.375rem;
          border-radius: 3.125rem;
          display: flex;
          text-align: center;
          color: #333333;
          align-items: center;
          justify-content: start;
          cursor: pointer;
          font-size: 1.5625rem;

          a {
            transition: all .25s ease-out;
            text-decoration: none !important;
          }

          .iconfont {
            font-size: 1.75rem;
            display: inline-block;
            margin-left: 10px;
          }
        }
      }

      .carousel {
        height: 100%;
        margin: 0 auto;
        padding: 110px 15px 0;
        box-sizing: border-box;
        text-align: center;

        h1 {
          font-weight: bold;
          color: #333333;
          text-align: center;
        }

        .download-sub-title {
          font-size: 1.625rem;
          color: #333333;
          text-align: center;
          margin-bottom: 60px;
        }

        .text-center {
          text-align: center;

          .type {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .tip {
            font-size: .875rem;
          }

          .download-link-a {
            background-color: #FF930E;
            border-radius: 28px;
            padding: 0 30px;
            height: 3.5rem;
            line-height: 3.5rem;
            border: 0;
            color: #FFFFFF;
            font-size: 1.125rem;
            margin-bottom: 10px;
          }

          .free-trail {
            font-size: .9375rem;
            color: #333333;
            margin-top: 15px;
            margin-bottom: 30px;
          }
        }

        .app-changelog {
          text-align: right;
          margin: 60px 0 10px;
        }
      }
    }
  }


  .main {
    color: #33383B;

    .why-flyvpn {
      position: relative;
      padding: 0px 0 100px;

      .download-img-box {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        margin-top: -40px;
        padding-bottom: 60px;

        .download-img-box-title {
          font-size: 2.875rem;
          font-weight: bold;
          color: #333333;
          text-align: center;
          padding-top: 80px;
        }

        img {
          display: block;
          width: 70%;
          margin: 60px auto;
        }

        .download-img-box-pc-desc {
          font-size: 1rem;
          color: #666666;
          margin: 0 120px;
        }
      }

      .get-it-btn {
        margin-top: 20px;
        text-align: center;
      }

      a {
        margin-top: 30px;
        height: 3.5rem;
        line-height: 3.5rem;
        background: rgba(56, 143, 255, 1);
        box-shadow: 0px 3px 6px rgba(56, 143, 255, 1);
        border-radius: 1.9375rem;
        padding: 0 33px;
        font-weight: bold;
        color: #FFFFFF;
      }

    }

    .container-div {
      padding: 0 0 100px;

      .home-body-title {
        max-width: 80%;
        font-size: 2.875rem;
        font-weight: bold;
        color: #333333;
        text-align: center;
        margin: 60px auto 100px;

      }

      .row {
        width: 100%;
        // margin-right: -15px;
        // margin-left: -15px;
        box-sizing: border-box;
        margin: 0 auto;



        .clearfix {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
        }

        .feature-item {
          margin-top: 0;

          .feature-list-left,
          .feature-list-right {

            text-align: center;
            margin-bottom: 20px;
            // padding: 10px;
            // word-wrap: wrap;
            // box-sizing: border-box;
          }

          img {
            width: 8.125rem;
            height: 8.125rem;
            margin-bottom: 30px;
          }

          .feature-list-content {
            margin: auto;
            max-width: 240px;

            h2 {
              font-size: 1.75rem;
              font-weight: bold;
              color: #333333;
              margin-bottom: 20px;
              margin-top: 0;
            }

            span {
              font-size: 1rem;
              color: #666666;
              word-wrap: wrap;
            }

            a {
              color: #FF930E;
            }
          }
        }
      }

    }

  }

  .footer {
    box-sizing: border-box;
    width: 100%;
    margin: 0px;
    padding: 40px 0px;
    background: #2b2c34;
    text-align: center;

    a {
      color: #FF930E;
    }

    .agreements {
      display: flex;
      justify-content: center;
      align-items: center;

      .agreement {
        color: #fff;
        font-size: 1rem;
        margin-right: 10px;
      }
    }

    .copyright {
      font-size: .875rem;
      color: #acacac;
      margin-top: 10px;
    }
  }
}
</style>
